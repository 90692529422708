import React from "react";
import { PiCaretRight } from "react-icons/pi";
import { useLocation } from "react-router-dom";

const BreadCrumb = () => {
  const { pathname } = useLocation();
  console.log(pathname);
  const wordsAfterSlash = pathname.split("/").filter((word) => word !== "");
  const formattedWords = wordsAfterSlash.map((word) => word.replace(/-/g, " "));
  const resultArray = formattedWords;

  return (
    <div className="mb-3">
      <div className="flex gap-1 md:gap-3 items-center text-xs sm:text-sm md:ml-8">
        {resultArray.map((item, index) => {
          return (
            <>
              <p
                className={
                  index === resultArray.length - 1
                    ? `opacity-50 capitalize`
                    : `capitalize`
                }
              >
                {item}
              </p>
              {index !== resultArray.length - 1 && <PiCaretRight />}
            </>
          );
        })}
      </div>
    </div>
  );
};

export default BreadCrumb;
