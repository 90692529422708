import React from "react";
import {
  BiLogoFacebookCircle,
  BiLogoLinkedinSquare,
  BiLogoInstagramAlt,
  BiLogoTwitter,
  BiLogoYoutube,
} from "react-icons/bi";

const Footer = () => {
  return (
    <footer className="bg-black text-white">
      <div className="container mx-auto px-4 py-8 md:py-16 lg:py-24">
        <div className="grid grid-cols-1 lg:grid-cols-6 gap-y-8 lg:gap-x-6">
          <div>
            <ul className="grid gap-y-2">
              <li className="mb-2">
                <ul className="flex gap-3">
                  <li>
                    <BiLogoFacebookCircle size="1.375rem" />
                  </li>
                  <li>
                    <BiLogoInstagramAlt size="1.375rem" />
                  </li>
                  <li>
                    <BiLogoLinkedinSquare size="1.375rem" />
                  </li>
                  <li>
                    <BiLogoTwitter size="1.375rem" />
                  </li>
                  <li>
                    <BiLogoYoutube size="1.375rem" />
                  </li>
                </ul>
              </li>
              <li>Tel: +234809466373</li>
              <li>tech4all@gmail.com</li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-3 md:mb-6 md:text-xl">
              Programs
            </h6>
            <ul className="grid gap-y-2 font-light text-sm">
              <li>Engineering</li>
              <li>Product</li>
              <li>Quality Assurance</li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-3 md:mb-6 md:text-xl">
              Specialisations
            </h6>
            <ul className="grid gap-y-2 font-light text-sm">
              <li>Frontend Engineering</li>
              <li>Backend Engineering</li>
              <li>Product Design</li>
              <li>Quality Assurance</li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-3 md:mb-6 md:text-xl">
              Compainies
            </h6>
            <ul className="grid gap-y-2 font-light text-sm">
              <li>Hire our Grads</li>
              <li>Collaborate with us</li>
              <li>Scholarships Assurance</li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-3 md:mb-6 md:text-xl">
              About Us
            </h6>
            <ul className="grid gap-y-2 font-light text-sm">
              <li>Our Story</li>
              <li>Blog</li>
            </ul>
          </div>
          <div>
            <h6 className="font-bold text-lg mb-3 md:mb-6 md:text-xl">
              Resources
            </h6>
            <ul className="grid gap-y-2 font-light text-sm">
              <li>Privacy and Policy</li>
              <li>FAQs</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-black text-center py-4 md:py-6">
        <p>All right reserved. Tech4All &copy; 2023</p>
      </div>
    </footer>
  );
};

export default Footer;
