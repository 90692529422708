import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import Contact from "./pages/Contact";
import Frontend from "./pages/Program/Frontend";
import About from "./pages/About";
import Backend from "./pages/Program/Backend";
import Product from "./pages/Program/Product";
import QA from "./pages/Program/QA";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/partner" element={<Contact />} />
          <Route path="/programs/frontend-engineering" element={<Frontend />} />
          <Route path="/programs/backend-engineering" element={<Backend />} />
          <Route path="/programs/product" element={<Product />} />
          <Route path="/programs/quality-assurance" element={<QA />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
