import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { SiProducthunt } from "react-icons/si";
import { MdAssuredWorkload } from "react-icons/md";
import { BsCodeSlash } from "react-icons/bs";
import { Link } from "react-router-dom";

export const ProgramCard = ({ program, logo, link }) => {
  return (
    <div
      className={`w-full sm:max-w-xs lg:max-w-sm 2xl:max-w-md bg-white rounded-lg border`}
    >
      <div className="rounded-lg border py-3 pb-2 px-3">
        <div className="text-royal px-4 py-4 rounded-t-lg">{logo}</div>
        <div className="my-4 px-4">
          <h3 className="font-semibold text-lg md:text-xl mb-2 text-royal">
            {program}
          </h3>
          <div className="">
            <p className="w-11/12">
              Learn Engineering fundamentals and apply them to real life
              problems.
            </p>

            <div className="flex justify-between w-full mt-4 font-medium">
              <Link
                to={link}
                className="flex items-center gap-2 my-3 text-teal"
              >
                Learn More <AiOutlineArrowRight />
              </Link>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSced1KU2OdOg_S10sEu408wB11WU-G1hm8AA_WZKavWbav9Rw/viewform"
                className="flex items-center gap-2 my-3 text-teal border border-teal px-4 rounded-md py-2"
              >
                Enrol Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Programs = () => {
  return (
    <div className="bg-primary-blue py-8">
      <div className="container mx-auto px-4">
        <div className="md:pb-16 lg:pb-28 flex flex-col justify-between">
          <div className="my-4 md:my-8">
            <h2 className="uppercase text-lg text-center text-royal font-bold">
              Programs
            </h2>
            <p className="text-center text-xl md:text-2xl my-2 font-semibold">
              Our Avialable Programs
            </p>
          </div>
          <div className="flex flex-col flex-wrap sm:flex-row gap-y-4 md:gap-y-8 gap-x-5 lg:gap-x-0 justify-center lg:justify-between">
            <ProgramCard
              program="Frontend Engineering"
              logo={<BsCodeSlash size="2.5rem" />}
              link="/programs/frontend-engineering"
            />
            <ProgramCard
              program="Backend Engineering"
              logo={<SiProducthunt size="2.5rem" />}
              link="/programs/backend-engineering"
            />
            <ProgramCard
              program="Quality Assurance"
              logo={<MdAssuredWorkload size="2.5rem" />}
              link="/programs/quality-assurance"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
