import React from "react";
import about from "../../assets/about3.avif";
import about2 from "../../assets/about2.avif";
import Partner from "../../components/Partner";

const About = () => {
  return (
    <div className="py-32 bg-primary-blue">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto pb-8 sm:pb-16">
          <h1 className="text-2xl sm:text-3xl md:text-4xl text-royal my-4 font-bold tracking-wide">
            About Us
          </h1>
          <p className="my-4 max-w-3xl text-lg md:text-xl tracking-wider">
            Tech4All is a leading technology training provider, built to
            accelerate the careers of young Africans through the technology and
            professional skills that enable them to thrive in the digital
            economy.
          </p>
        </div>
        <div className="max-w-6xl mx-auto">
          <div className="grid grid-cols-2 gap-x-5 md:gap-x-10">
            <img src={about} alt="laptop" className="max-h-[650px]" />
            <img src={about2} alt="laptop" className="" />
          </div>
        </div>

        <div className="my-12 md:my-24">
          <div className="grid grid-cols-12">
            <div className="col-span-full md:col-span-3">
              <p className="text-xl sm:text-2xl md:text-3xl font-bold text-royal">
                Who are We ?
              </p>
            </div>
            <div className="col-span-full md:col-span-9">
              <p>
                Tech4All is a premier ICT Experience and Training Institute
                boasting 8 years of collective staff expertise across various
                technological domains, encompassing innovation, development, and
                instruction. As a Tech Talent cultivator, we specialize in
                empowering youth to carve their tech niche, providing guidance
                towards professional maturation, and facilitating entry into the
                workforce or practical projects. Our online training offerings
                encompass self-paced and live virtual sessions, catering to
                individuals, corporations, and groups. With adaptable schedules,
                we address your training requirements seamlessly, offering
                flexibility for anytime, anywhere learning.
              </p>
            </div>
          </div>
        </div>

        <Partner className="bg-white" />
      </div>
    </div>
  );
};

export default About;
