import React from "react";
import Template from "../../components/Programs/ProgramTemplate";

const Product = () => {
  return (
    <Template
      title="Product"
      tagline="Master the fundamentals of UI design using Figma to create delightful user interfaces from scratch."
    ></Template>
  );
};

export default Product;
