import React from "react";
import aboutUs from "../../assets/nerd.svg";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { whyUs } from "../../utils/content";

const About = () => {
  const AboutCard = ({ title, icon, info }) => {
    return (
      <div className="bg-primary-blue rounded-lg px-4 md:px-6 py-4 md:py-8 min-w-[240px] pb-8  max-h-56">
        {icon}
        <h3 className="my-0.5 text-sm md:text-base font-semibold break-keep">
          {title}
        </h3>
        <p className="text-sm">{info}</p>
      </div>
    );
  };
  return (
    <div className="px-4 py-16 lg:py-24 md:grid sm:gap-16">
      <div className="bg-primary-blue">
        <div className="container mx-auto md:grid grid-cols-1 sm:grid-cols-2 py-5 md:py-10 items-center lg:gap-10 justify-items-start xl:justify-items-center 2xl:justify-items-start">
          <div className="flex-grow flex justify-center my-3 md:my-0">
            <div className="outline outline-offset-8 outline-1 rounded-full w-fit md:w-auto">
              <div className="bg-teal rounded-full relative w-40 h-40 md:w-56 md:h-56 lg:w-72 lg:h-72 outline outline-offset-2 outline-1">
                <img
                  src={aboutUs}
                  alt="about-us"
                  className="max-w-sm absolute -bottom-4 left-4 md:-bottom-[29px] md:left-9"
                />
              </div>
            </div>
          </div>
          <div className="flex-grow my-5 md:my-0">
            <div className="max-w-lg">
              <h2 className="text-lg md:text-xl lg:text-2xl font-bold">
                World class training and 100% placement ensured with course
                completion certification by our No.1 Training
              </h2>
              <p className="mt-3 mb-6 max-w-sm opacity-80">
                World class training and 100% placement ensured with course
                completion
              </p>
              <Link
                to="/about"
                className="border-1 border-transparent flex items-center justify-between bg-royal text-white w-5/6 lg:w-2/4 px-5 md:px-8 py-3 rounded-lg"
              >
                Explore More About Us
                <IoIosArrowForward />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-2 py-12 md:py-0">
        <div className="grid gap-5 md:gap-7 lg:gap-12 grid-cols-12">
          <div className="col-span-full md:col-span-4 lg:col-span-3">
            <p className="uppercase my-2 text-royal font-bold text-lg md:text-xl">
              Features
            </p>
            <h2 className="font-semibold text-xl md:text-2xl">
              Why Choose Us ?
            </h2>
            <p className="my-4 text-sm">
              When it comes to choosing the right training school for your
              educational journey, experience matters. At Tech4All, we take
              pride in our 8 years of unwavering commitment to delivering
              top-tier training and education.
            </p>
          </div>
          <div className="mb-8 col-span-full md:col-span-8 lg:col-span-9 w-full overflow-x-scroll no-scrollbar">
            <div className="flex gap-4 overflow-x-scroll no-scrollbar">
              {whyUs.map(({ title, info, icon }) => {
                return <AboutCard title={title} info={info} icon={icon} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
