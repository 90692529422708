import React from "react";
import Accordion from "../Accordion";

const Faq = () => {
  return (
    <div className="bg-primary-blue">
      <div className="container mx-auto px-2 md:px-4 py-16">
        <div className="my-6 md:my-16">
          <h2 className="text-center text-lg md:text-2xl font-medium text-royal my-5">
            Frequently Asked Questions
          </h2>
          <div className="mx-auto max-w-3xl 2xl:max-w-5xl bg-white pt-4 rounded-lg">
            <ul className="grid">
              <Accordion
                title="What is Tech4All?"
                content="Tech4 All is an inclusive tech school offering a wide range of courses, including software testing, project management, and front-end development. Our mission is to make tech education accessible to everyone, regardless of their background or prior experience."
              />
              <Accordion
                title="How can I enroll?"
                content={`Enrolling in Tech4All is a straightforward process. You can visit our website and click on the 'Enroll Now' button. Follow the steps to create an account, choose your desired course, and complete the enrollment form.`}
              />
              <Accordion
                title="Who can enroll in Tech4All programs?"
                content={`Enrolling in Tech4All is a straightforward process. You can visit our website and click on the 'Enroll Now' button. Follow the steps to create an account, choose your desired course, and complete the enrollment form.`}
              />
              <Accordion
                title=" When do I need to pay for the course?"
                content="Payment details vary depending on the specific course you choose. Generally, you will be required to make a payment during the enrollment process. We offer flexible payment options, including one-time payments and installment plans. Be sure to review the payment information provided during enrollment."
              />
              <Accordion
                title="When will classes start for the course I've enrolled in?"
                content="Course start dates can vary based on the specific program you've chosen. Once you've completed your enrollment and payment, you will receive an email confirmation with detailed information about the course start date, schedule, and any pre-course materials or requirements. Typically, our courses have multiple start dates throughout the year to accommodate different learners' needs."
              />
              <Accordion
                title=" How long are the Tech4All courses?"
                content="
                The duration of our courses varies depending on the program and the level of proficiency you aim to achieve. Generally, our courses range from a few weeks for introductory courses to several months for more comprehensive programs. You can find specific course durations on our website or in the course description during enrollment
                ."
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
