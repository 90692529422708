import React from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { Link } from "react-router-dom";

const Partner = ({ className }) => {
  return (
    <div className="container mx-auto">
      <div className="py-16 md:py-20 lg:pb-28">
        <div className={`${className} p-4 md:p-12`}>
          <div className="grid gap-y-3">
            <h2 className="text-xl md:text-2xl font-semibold">
              Partner with Us
            </h2>
            <p>
              Are you passionate about shaping the future of tech education? Do
              you believe in the power of coding to transform lives and
              industries? We invite you to explore exciting partnership
              opportunities with Tech4All. Partnering with Tech4All is an
              opportunity to invest in education, innovation, and the future of
              tech. Together, we can empower individuals to unlock their full
              potential and drive positive change in the tech landscape.
            </p>
          </div>

          <Link
            to="/partner"
            className="flex items-center gap-2 bg-transparent text-royal mt-3 md:mt-5 py-3 px-6 md:px-8 max-w-max hover:bg-royal border-royal border hover:text-white rounded-lg"
          >
            Learn More <AiOutlineArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Partner;
