import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import { BiCaretDown } from "react-icons/bi";
import logo from "../../assets/logo.png";

const Nav = () => {
  const [show, setShow] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  const handleSubMenuShow = () => {
    setShowSubMenu(!showSubMenu);
  };
  return (
    <div className="shadow md:shadow-none fixed w-full z-50 bg-white">
      <nav className="flex container mx-auto px-4 justify-between items-center py-5 ">
        <div>
          <Link to="/" className="text-2xl font-bold flex items-center">
            <img src={logo} alt="Tech-4-All Logo" className="w-12" />
            <span className="text-royal">Tech4All</span>
          </Link>
        </div>
        <ul
          className={`${
            show ? "md:flex" : "hidden md:flex"
          } flex h-screen md:h-auto bg-white top-0 bottom-0 fixed right-0 md:relative flex-col md:flex-row gap-0 md:gap-5 lg:gap-0 md:justify-between items-center w-2/3 md:w-auto`}
        >
          <li className="md:hidden self-end mt-2 mr-4" onClick={handleShow}>
            <AiOutlineClose />
          </li>
          <li>
            <ul className="flex flex-col md:flex-row mt-8 md:mt-0 gap-6 md:gap-4 items-center">
              <li>
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive ? "text-royal font-bold" : "font-semibold"
                  }
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about-us"
                  className={({ isActive }) =>
                    isActive ? "text-royal font-bold" : "font-semibold"
                  }
                >
                  About Us
                </NavLink>
              </li>
              <li className="group relative">
                <NavLink
                  to="#"
                  className={({ isActive }) =>
                    `${isActive ? "text-royal font-bold" : "font-semibold"}  `
                  }
                >
                  <span
                    className="flex items-center justify-center md:justify-normal"
                    onClick={() => handleSubMenuShow()}
                  >
                    {" "}
                    Our Programs
                    <BiCaretDown />
                  </span>
                </NavLink>
                {showSubMenu && (
                  <ul
                    className=" md:absolute bg-white text-black md:border font-normal md:w-52 text-sm flex-col gap-5 py-2 md:border-black md:rounded-lg mt-3 ml-4 md:ml-0"
                    onClick={handleSubMenuShow}
                  >
                    <li className="md:border-b px-4 py-2">
                      <Link
                        to="/programs/frontend-engineering"
                        className="hover:text-teal"
                      >
                        Frontend Engineering
                      </Link>
                    </li>
                    <li className="md:border-b px-4 py-2">
                      <Link
                        to="/programs/backend-engineering"
                        className="hover:text-teal"
                      >
                        Backend Engineering
                      </Link>
                    </li>
                    {/* <li className="md:border-b px-4 py-2">
                      <Link to="/programs/product" className="hover:text-teal">
                        Product
                      </Link>
                    </li> */}
                    <li className="md:border-b px-4 py-2">
                      <Link
                        to="/programs/quality-assurance"
                        className="hover:text-teal"
                      >
                        Quality Assurance
                      </Link>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <NavLink
                  to="/partner"
                  className={({ isActive }) =>
                    isActive ? "text-royal font-bold" : "font-semibold"
                  }
                >
                  Partner With Us
                </NavLink>
              </li>
            </ul>
          </li>
        </ul>

        {!show && (
          <RxHamburgerMenu
            size="1.5rem"
            className="md:hidden"
            onClick={handleShow}
          />
        )}
      </nav>
    </div>
  );
};

export default Nav;
