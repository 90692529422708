import React, { useState } from "react";
import { RxCaretDown, RxCaretUp } from "react-icons/rx";

const Accordion = ({ title, content }) => {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  return (
    <div className="px-8 md:px-12 border-b py-3">
      <li>
        <h5
          className="flex justify-between text-lg items-center w-full font-semibold hover:text-teal hover:cursor-pointer"
          onClick={handleShow}
        >
          {title}{" "}
          {show ? (
            <RxCaretUp className="text-teal" size="2rem" />
          ) : (
            <RxCaretDown className="text-teal" size="2rem" />
          )}
        </h5>
        {show && <p className="pt-2 pb-12">{content}</p>}
      </li>
    </div>
  );
};

export default Accordion;
