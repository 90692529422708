import React from "react";
import BreadCrumb from "../BreadCrumb";

const ProgramTemplate = ({
  title,
  tagline,
  courseOutline,
  learningObjective,
  description,
  header,
}) => {
  // Accessing the course outline for Month 2, Week 3:
  const month = 2;
  const week = 3;
  const topic = courseOutline[month - 1].topics[week - 1];
  console.log(`Month ${month}, Week ${week}: ${topic}`);
  return (
    <div className="bg-white py-24">
      <div className="container mx-auto px-4 my-8">
        <BreadCrumb />
        <div className="py-8 md:py-16 lg:py:24">
          <div className="max-w-5xl mx-auto text-center">
            <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl tracking-wide text-royal font-semibold">
              Learn {title}
            </h1>
            <p className="my-4 md:my-6 max-w-4xl mx-auto md:text-lg tracking-wider">
              {tagline}
            </p>
            <div className="flex items-center gap-3 md:gap-6 justify-center my-8">
              <a
                className="rounded-lg bg-teal text-sm sm:text-base text-white py-3 px-4 md:px-8 hover:border hover:border-teal hover:text-teal"
                href="/"
              >
                Enroll Now
              </a>
              <a
                className="rounded-lg hover:bg-teal text-sm sm:text-base hover:text-white py-3 px-4 md:px-8 border border-teal text-teal"
                href="/"
              >
                Download Syllabus
              </a>
            </div>
          </div>
        </div>
        <div className="py-4 sm:py-8 md:py-16">
          <div className="grid grid-cols-12">
            <div className="col-span-full md:col-span-3 grid md:grid-cols-3 pb-5 md:pb-0">
              <ul className="flex flex-wrap justify-between md:flex-col gap-3 pb-1 col-span-full md:col-span-2">
                <li>
                  <p className="text-base sm:text-lg font-semibold">
                    Skill Level
                  </p>
                  <p className="text-sm sm:text-base">Beginner</p>
                </li>
                <li>
                  <p className="text-base sm:text-lg font-semibold">
                    Course model
                  </p>
                  <p className="text-sm sm:text-base">Online</p>
                </li>
                <li>
                  <p className="text-base sm:text-lg font-semibold">
                    Guided learning
                  </p>
                  <p className="text-sm sm:text-base">Mentorship</p>
                </li>
                <li>
                  <p className="text-base sm:text-lg font-semibold">Outcome</p>
                  <p className="text-sm sm:text-base">Certificate</p>
                </li>
              </ul>
              <div className="border md:w-0 hidden md:block col-span-1"></div>
            </div>

            <div className="col-span-full md:col-span-9">
              <h2 className="text-xl font-semibold">{header}</h2>
              <p className="text-sm sm:text-base md:text-lg mt-3">
                {description}
              </p>
            </div>
          </div>
        </div>

        <div className="py-4 sm:py-8 md:py-16 lg:py-24">
          <div className="grid grid-cols-12 gap-3 md:gap-6">
            <div className="col-span-full md:col-span-5">
              <h3 className="text-xl sm:text-2xl md:text-4xl lg:text-5xl font-semibold mb-4 tracking-wide ">
                What you will Learn
              </h3>
              <p className="max-w-md text-sm sm:text-base tracking-wider">
                {learningObjective}
              </p>
            </div>
            <div className="col-span-full md:col-span-7 py-8 md:py-0">
              <div className="border border-black py-2 w-full rounded-br-lg rounded-tl-lg ">
                <div className="px-4 md:px-8 py-2 border-b border-black">
                  <p className="font-semibold text-lg md:text-xl">
                    Course Outline
                  </p>
                </div>
                <ul>
                  {courseOutline.slice(0, 3).map((monthObj, monthIndex) => (
                    <li
                      key={monthIndex}
                      className="border-b border-black px-5 py-4"
                    >
                      <p className="font-medium">{monthObj.month}</p>
                      <ul>
                        {monthObj.topics.slice(0, 3).map((topic, weekIndex) => (
                          <li
                            key={weekIndex}
                            className={` px-4 md:px-6 py-1 flex w-full gap-4 text-sm sm:text-base ${
                              monthIndex === 2 ?? "opacity-45"
                            }`}
                          >
                            <p className="font-medium">Week {weekIndex + 1}</p>
                            <p>{topic}</p>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>

                <div className="my-5 w-full px-3 sm:px-5 md:px-7 lg:px-9">
                  <button className="bg-black hover:bg-transparent hover:border-black hover:border text-white hover:text-black w-full py-3 font-medium">
                    Download full course Outline
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramTemplate;
