import React from "react";

const PartnerCard = ({ title, description, buttonLabel }) => {
  return (
    <div className="bg-primary-blue rounded-lg py-6 px-2 m-1 shadow-lg">
      <h2 className="text-xl font-semibold mb-4">{title}</h2>
      <p className="text-gray-600 text-sm max-w-[90%] text-center">
        {description}
      </p>
    </div>
  );
};

export default PartnerCard;
