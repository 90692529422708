import React from "react";
import Template from "../../components/Programs/ProgramTemplate";

const courseOutline = [
  {
    month: "Introduction to the Web and HTML",
    topics: [
      "Introduction to Frontend Engineering",
      "How the Internet Works",
      "Domain Name System (DNS)",
      "HTTP and Web Hosting",
      "HTML Fundamentals",
      "HTML Forms and Accessibility",
      "SEO Basics",
      "Project - HTML",
    ],
  },
  {
    month: "Cascading Style Sheets (CSS)",
    topics: [
      "Basics of CSS",
      "CSS Positioning and Layout",
      "Responsive Web Design",
      "CSS Libraries and Frameworks",
      "JavaScript Basics",
    ],
  },
  {
    month: "JavaScript and DOM Manipulation",
    topics: [
      "DOM Manipulation",
      "Working with APIs",
      "In-depth JavaScript Concepts",
      "Project - HTML, CSS, and JavaScript",
    ],
  },
  {
    month: "Version Control and Build Tools",
    topics: [
      "Version Control with Git",
      "GitHub and GitLab",
      "Frameworks and Build Tools",
      "Linters, Formatters, and Task Runners",
    ],
  },
  {
    month: "React Basics and Advanced Topics",
    topics: [
      "Introduction to React",
      "React Hooks and Testing",
      "Advanced React Concepts",
    ],
  },
  {
    month: "Advanced Project and Course Conclusion",
    topics: [
      "Advanced Project - Building a Complex Web Application",
      "Course Conclusion and Portfolio Development",
    ],
  },
];

const learningObjective =
  "You'll start by building a strong foundation in web development. We'll demystify the internet, covering essential concepts like DNS, HTTP, and web hosting. You'll become proficient in HTML, the language of web content, mastering everything from structuring web pages to creating forms and optimizing for accessibility and SEO. You'll then dive into the realm of cascading style sheets (CSS), learning how to style web pages, control layouts, and create responsive designs. We'll explore CSS frameworks like Bootstrap and Tailwind CSS. Next, you'll harness the power of JavaScript, starting with the basics and progressing to advanced topics like DOM manipulation, API interactions, and mastering crucial JavaScript concepts.";

const description =
  "Prepare to embark on a journey into the exciting world of web development. This comprehensive six-month course will equip you with the essential skills and knowledge needed to become a proficient frontend engineer. From building web pages with HTML and CSS to mastering JavaScript and modern frameworks like React, you'll gain hands-on experience, create real-world projects, and build a solid foundation for a successful career in web development. Join us and unlock the doors to endless possibilities in the digital realm.";

const header = "Welcome to the Frontend Engineering Course! 🚀";

const Frontend = () => {
  return (
    <Template
      title="Frontend Engineering"
      header={header}
      tagline="Master the fundamentals of frontend development using HTML, CSS, JavaScript (Libraries and Frameworks) to create delightful user interfaces."
      courseOutline={courseOutline}
      learningObjective={learningObjective}
      description={description}
    />
  );
};

export default Frontend;
