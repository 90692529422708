import React from "react";
import illustration from "../../assets/innovation-amico.svg";
import { AiOutlineArrowRight } from "react-icons/ai";

const Hero = () => {
  return (
    <div className="bg-primary-blue pt-40 py-24 lg:pt-24 xl:pt-40">
      <div className="container mx-auto px-4 grid md:grid-cols-2 md:justify-between items-center">
        <div className="order-2 md:order-1">
          <h1 className="font-bold text-3xl md:text-4xl lg:text-5xl max-w-lg tracking-wider">
            Acquire World-Class Tech Skills To Get Your Dream Tech Job
          </h1>
          <p className="mt-2 md:mt-3 font-medium text-base md:text-lg max-w-xl">
            We get you from novice to job-ready in 6 months through our programs
            led by instructors from leading companies
          </p>
          <div className="flex flex-col md:flex-row gap-2 my-3 md:mt-6 lg:mt-8 ">
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSced1KU2OdOg_S10sEu408wB11WU-G1hm8AA_WZKavWbav9Rw/viewform"
              className="text-royal hover:text-white bg-white hover:bg-royal border border-royal px-4 py-3 rounded-lg font-semibold text-sm md:px-8"
            >
              Apply Now
            </a>
            <a
              href="/about"
              className="py-3 px-4 text-sm text-royal md:px-8 gap-2 rounded-md font-semibold flex items-center"
            >
              Learn More <AiOutlineArrowRight />
            </a>
          </div>
        </div>
        <div className="order-1 md:order-2 mb-4 md:mb-0 flex justify-center">
          <img src={illustration} alt="tech" className="lg:max-w-lg" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
