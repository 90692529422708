import React from "react";
import PartnerCard from "../../components/Partner/PartnerCard";
import { AiOutlineArrowRight } from "react-icons/ai";

const PartnerPage = () => {
  return (
    <div className="bg-primary-blue min-h-screen py-12 px-4 md:py-24 lg:pt-24 xl:pt-40">
      <div className="container mx-auto text-center">
        <header className="mb-12">
          {/* Title */}
          <h1 className="text-4xl font-semibold text-royal mb-2">
            Partner with Us
          </h1>

          {/* Description */}
          <p className="text-lg">
            Join us in shaping the future of education and technology.
          </p>

          {/* Call to Action Button */}
        </header>
        <div className="flex justify-between my-6 md:mb-10">
          <div className="max-w-2xl text-left ">
            <p className="font-semibold md:text-xl">Partnership Types</p>
            <p>
              We offer diverse partnership opportunities and engage with a broad
              range of organizations across different industries and sizes,
              ensuring a unique collaboration experience.
            </p>
          </div>
          <div>
            <a
              href="https://docs.google.com/forms/d/e/1FAIpQLSeN0Z6ioAhjDlYwD7l5B6iXrk5Vqn1BfFBxqOvha0wlKdpLAQ/viewform"
              className="flex items-center gap-2 bg-transparent text-royal mt-3 md:mt-5 py-3 px-6 md:px-8 max-w-max hover:bg-royal border-royal border hover:text-white rounded-lg"
            >
              Partner with us
              <AiOutlineArrowRight />
            </a>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
          <PartnerCard
            title="Learning Partner"
            description="Collaborate with us to shape the next generation of top technologists by co-creating exceptional educational content across diverse fields. Join forces to craft high-quality learning materials."
          />
          <PartnerCard
            title="Internship Partner"
            description="Gain access to a pool of talented junior technologists who possess a perfect blend of skills, passion, and drive. If you're seeking exceptional team members, click the button to secure the ideal talent for your needs."
          />
          <PartnerCard
            title="Sponsorship Partner"
            description="You have the opportunity to sponsor students or groups for specialized courses, empowering them with essential skills and comprehensive knowledge in their chosen field. Your sponsorship will play a pivotal role in enhancing their expertise and contributing to their growth."
          />
          <PartnerCard
            title="Funding Collaborator"
            description="We are providing Africans with essential skills for lucrative tech careers at significantly reduced costs. Despite these benefits, many individuals are unable to enroll due to economic challenges. To support promising talents, we offer student scholarships and sponsorships. If you're interested in empowering these individuals, please consider clicking the button below."
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerPage;
