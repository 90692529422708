import React from "react";
import Template from "../../components/Programs/ProgramTemplate";

const courseOutline = [
  {
    month: "Month 1: Introduction to the Web and Basics",
    topics: [
      "How does the Internet work",
      "Domain Name System (DNS)",
      "What is HTTP",
      "Web Hosting",
      "Clients and Servers",
      "Version Control Systems",
      "What is Git and how to use it",
      "GitHub",
      "GitLab",
    ],
  },
  {
    month: "Month 2: Programming Fundamentals with JavaScript",
    topics: [
      "JavaScript Syntax and Basics",
      "In-depth JavaScript Concepts",
      "Hoisting",
      "Event Bubbling",
      "Classes, Scope, Prototype, etc.",
    ],
  },
  {
    month: "Month 3: Exploring Node.js and Databases",
    topics: [
      "Introduction to Node.js",
      "Relational and NoSQL Databases",
      "PostgreSQL",
      "MySQL",
      "MongoDB",
      "Firebase",
      "Redis",
      "Neo4j",
    ],
  },
  {
    month: "Month 4: Advanced Database Concepts",
    topics: [
      "Object-Relational Mapping (ORMs)",
      "ACID Principles",
      "Transactions",
    ],
  },
  {
    month: "Month 5: Operating Systems and General Knowledge",
    topics: [
      "How OSs work in General",
      "Memory Management",
      "Interprocess Communication, etc.",
    ],
  },
];

const learningObjective =
  "Develop a deep understanding of web architecture, from internet fundamentals to version control using Git and collaborative platforms like GitHub and GitLab. Master JavaScript programming, covering syntax, advanced concepts, and object-oriented principles, paving the way for backend development with Node.js. Explore a variety of databases, both relational and NoSQL, and dive into advanced database concepts like Object-Relational Mapping (ORMs) and ACID principles. Gain insights into operating systems, understanding their core functionality, memory management, and interprocess communication.";

const description =
  "Uncover the magic behind the scenes of the digital world as you delve into the realm of backend development. Over the course of six months, you'll embark on an enlightening journey, from understanding the core principles of web architecture to mastering programming languages like JavaScript and Node.js. Explore databases, version control, and operating systems, equipping yourself with the skills needed to craft robust and scalable backend solutions. Get ready to bring data and functionality to life in the digital realm!";

const header = "Welcome to the Backend Engineering Course! 🌐";

const Backend = () => {
  return (
    <Template
      title="Backend Engineering"
      header={header}
      tagline="Master Backend Engineering fundamentals, including programming in JavaScript and Node.js, and database management. Create robust server-side solutions for web applications and services"
      courseOutline={courseOutline}
      learningObjective={learningObjective}
      description={description}
    />
  );
};

export default Backend;
