import React from "react";
import Hero from "../../components/Hero";
import About from "../../components/About";
import Programs from "../../components/Programs";
import Partner from "../../components/Partner";
import Faq from "../../components/FAQ";

const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <Programs />
      <Partner className="bg-primary-blue" />
      <Faq />
    </div>
  );
};

export default Home;
