import React from "react";
import Template from "../../components/Programs/ProgramTemplate";

const courseOutline = [
  {
    month: "Module 1: Software Testing Introduction",
    topics: [
      "Importance of testing",
      "What is testing?",
      "Roles and Responsibilities",
      "Principles of software testing",
    ],
  },
  {
    month: "Module 2: Software Development Life Cycle",
    topics: [
      "SDLC Phases",
      "SDLC Models (Waterfall, V Model, Agile, Prototype, Spiral)",
    ],
  },
  {
    month: "Module 3: Software Testing Methodologies",
    topics: ["White Box Testing", "Black Box Testing", "Grey Box Testing"],
  },
  {
    month: "Module 4: Test Case Design Techniques",
    topics: [
      "Static Techniques",
      "Dynamic Techniques",
      "Experienced-Based Techniques",
    ],
  },
  {
    month: "Module 5: Levels of Testing",
    topics: ["Functional Testing Levels", "Non-Functional Testing Levels"],
  },
  {
    month: "Module 6: Software Testing Life Cycle",
    topics: [
      "Requirements Analysis/Design",
      "Test Planning",
      "Test Cases Design",
      "Test Environment setup",
      "Test Execution",
      "Test Closure",
      "Test Metrics",
    ],
  },
  {
    month: "Module 7: QA & QC & Testing",
    topics: [
      "Quality Assurance vs. Quality Control",
      "Test Management with TFS Tool",
      "Defect Tracking Tools (TFS)",
    ],
  },
  {
    month: "Module 8: Backend Engineering Introduction",
    topics: [
      "Importance of backend development",
      "Web architecture fundamentals",
      "Programming languages (JavaScript)",
      "Database concepts",
      "Operating systems",
    ],
  },
  {
    month: "Module 9: Backend Development",
    topics: [
      "Node.js",
      "Database management (SQL and NoSQL)",
      "API development",
      "Scalability and performance",
    ],
  },
  {
    month: "Module 10: Advanced Topics",
    topics: [
      "Microservices architecture",
      "Serverless computing",
      "Security considerations",
    ],
  },
];

const learningObjective =
  "In comprehensive course, you'll gain a deep understanding of software testing and quality assurance, pivotal in delivering high-quality software. You'll explore testing methodologies, effective test case design, and defect management, ensuring comprehensive quality control. Understanding the software development life cycle (SDLC) phases, you'll contribute effectively to projects. Additionally, you'll master quality assurance principles, tools, and practices, ensuring software meets requirements and industry standards. By course end, you'll be equipped to deliver reliable, high-quality software that consistently meets user expectations.";

const description =
  "Welcome to the Quality Assurance Course, where you'll gain a deep understanding of quality assurance practices in the software development process. This comprehensive program will empower you with the knowledge and skills needed to drive quality and excellence in software products. Explore the principles of quality assurance, testing methodologies, and quality control, and discover how to log and manage software defects effectively. Whether you're a seasoned professional or new to the field, this course will enhance your ability to ensure software meets the highest standards of quality.";

const header = "Quality Assurance Course: Ensuring Excellence in Software 📊";

const QA = () => {
  return (
    <Template
      title="Quality Assurance"
      header={header}
      tagline="Master the fundamentals of Quality Assurance (QA) to ensure software products meet quality standards and user expectations."
      courseOutline={courseOutline}
      learningObjective={learningObjective}
      description={description}
    />
  );
};

export default QA;
