import { PiCertificateDuotone, PiSteps } from "react-icons/pi";
import { FaListCheck } from "react-icons/fa6";
import { TbUsersGroup, TbTools, TbGrowth } from "react-icons/tb";
import { VscPassFilled } from "react-icons/vsc";
import { SiGooglescholar } from "react-icons/si";
import { HiOutlineSupport } from "react-icons/hi";
import { MdOutlineConnectWithoutContact } from "react-icons/md";

export const whyUs = [
  {
    title: "Proven Track Record",
    info: "Our track record and alumni network validate our quality education and training.",
    icon: <PiCertificateDuotone size="2.5rem" className="text-teal" />,
  },
  {
    title: "Expert Faculty",
    info: "Learn from industry pros who've walked your desired path.",
    icon: <TbUsersGroup color="#93E9BE" size="2.25rem" className="text-teal" />,
  },
  {
    title: "Tailored Curriculum",
    info: "Industry-specific skills for all levels, from beginners to advanced learners.",
    icon: <FaListCheck size="2rem" className="text-teal" />,
  },
  {
    title: "Cutting-Edge Resources",
    info: "Access real-world scenarios with the latest tools, tech, and teaching methods.",
    icon: <TbTools size="2rem" className="text-teal" />,
  },
  {
    title: "Industry Connections",
    info: "Gain valuable industry connections for exciting opportunities.",
    icon: <MdOutlineConnectWithoutContact size="2rem" className="text-teal" />,
  },
  {
    title: "Personalized Support",
    info: "Our student-centric approach ensures individualized attention for an enriching educational journey.",
    icon: <HiOutlineSupport size="2rem" className="text-teal" />,
  },
  {
    title: "Dynamic Learning Environment",
    info: "Fostering discussion, collaboration, and hands-on experience for a dynamic learning environment.",
    icon: <SiGooglescholar size="2rem" className="text-teal" />,
  },
  {
    title: "Holistic Growth",
    info: "We cover technical skills, soft skills, communication, and personal development for a well-rounded professional journey. ",
    icon: <PiSteps size="2rem" className="text-teal" />,
  },
  {
    title: "Continuous Improvement",
    info: "We thrive on feedback, industry changes, and program enhancements to ensure ongoing relevance and effectiveness.",
    icon: <TbGrowth size="2rem" className="text-teal" />,
  },
  {
    title: "Success Stories",
    info: "Our excellence is showcased through our graduates' remarkable career achievements, a source of immense pride.",
    icon: <VscPassFilled size="2rem" className="text-teal" />,
  },
];
